import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql, StaticQuery, Link } from 'gatsby'

const TagCloud = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => {
  return (
    <ul className="taglist tagcloud">
      {group.map((tag) => (
        <li key={tag.fieldValue}>
          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  )
}

TagCloud.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ({ count }) => (
  <StaticQuery
    query={graphql`
      query TagCloud {
        allMarkdownRemark(limit: 1000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={(data) => <TagCloud data={data} />}
  />
)
